import { useHistory } from 'react-router-dom'
import PersonIcon from '../../assets/icons/person.png'
import EditIcon from '../../assets/icons/blue_pencil.png'
import { useOpenPDF } from '../../hooks/useOpenPDF'
import { useSelector } from 'react-redux'
import { TYPE } from '../../const/typeStudy'
import {
  Container,
  PatientNameImageExam,
  PatientMultipleData,
  LinksWrapper,
  NameAndExamWrapper,
  ImageWrapper,
  Image,
  FullName,
  ExamType,
  RowData,
  LabelAndInfoWrapper,
  DataLabel,
  DataInfo,
  InstructionsLink,
  EditDataWrapper,
  EditDataText,
  EditDataIcon,
  Button,
} from './styles'

export function PatientInformation ({ study, patientData, canEdit, withEvaluationsButton }) {
  const history = useHistory()
  const { user } = useSelector(state => state)
  const { openPDF } = useOpenPDF('pdf');

  const edad = () => {
    const fechaNacimiento = new Date(patientData.fechaNacimiento)
    const fechaActual = new Date()
    const edad = Math.floor((fechaActual - fechaNacimiento) / 1000 / 60 / 60 / 24 / 365)
    return edad
  }

  return (
    <Container>
      <PatientNameImageExam>
        <ImageWrapper>
          <Image src={PersonIcon} alt='patient' />
        </ImageWrapper>

        <NameAndExamWrapper>
          <FullName>{patientData && patientData.nombre +' '+patientData.apellido}</FullName>
          <ExamType> {study && TYPE[study.tipo]} </ExamType>
        </NameAndExamWrapper>
      </PatientNameImageExam>

      <PatientMultipleData>
        <RowData withBorder={!withEvaluationsButton}>
          <LabelAndInfoWrapper>
            <DataLabel> DNI </DataLabel>
            <DataInfo> {patientData && patientData.dni} </DataInfo>
          </LabelAndInfoWrapper>

          <LabelAndInfoWrapper>
            <DataLabel> Sexo </DataLabel>
            <DataInfo> {patientData && patientData.genero} </DataInfo>
          </LabelAndInfoWrapper>

          <LabelAndInfoWrapper>
            <DataLabel> Edad </DataLabel>
            <DataInfo> {patientData && edad()+' años'} </DataInfo>
          </LabelAndInfoWrapper>
        </RowData>

        {user.role === 'ophthalmologist' && <RowData withBorder={!withEvaluationsButton}>
          <LabelAndInfoWrapper>
            <DataLabel> Mail </DataLabel>
            <DataInfo> {patientData && patientData.email} </DataInfo>
          </LabelAndInfoWrapper>

          <LabelAndInfoWrapper>
            <DataLabel> Teléfono </DataLabel>
            <DataInfo> {patientData && patientData.telefono} </DataInfo>
          </LabelAndInfoWrapper>
        </RowData>}

        <RowData>
          <LabelAndInfoWrapper>
            <DataLabel> Peso </DataLabel>
            <DataInfo> {study && study.peso} kg </DataInfo>
          </LabelAndInfoWrapper>

          <LabelAndInfoWrapper>
            <DataLabel> Altura </DataLabel>
            <DataInfo> {study && study.altura} cm </DataInfo>
          </LabelAndInfoWrapper>
        </RowData>

      </PatientMultipleData>


      {canEdit && (
        <LinksWrapper>
          <EditDataWrapper>
            <EditDataText> Editar datos </EditDataText>
            <EditDataIcon src={EditIcon} alt='edit' />
          </EditDataWrapper>

          <InstructionsLink onClick={openPDF} target='_blank'>
            Instrucciones de captura
          </InstructionsLink>

        </LinksWrapper>
      )}

      {withEvaluationsButton && (
        <Button onClick={() => history.push('/evaluations')}> Ver estudios anteriores </Button>
      )}
    </Container>
  )
}

export default PatientInformation
