import { useSelector } from "react-redux"
import { useState } from "react"
import { URLS } from "../../const/nav"
export const useGetStudyFromPatientId = () => {

    const { user } = useSelector(state => state)
    const [study, setStudy] = useState(null)

    const getStudyFromPatientId = async (id) => {
        const response = await fetch(`${URLS.BACKEND_URL}/estudios/paciente/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${user.token}`
            }
        })
        const data = await response.json()
        setStudy(data)
    }

    return {study, getStudyFromPatientId}

}
