import styled, { css } from 'styled-components'
import { colors } from '../../../../../const'


const setColor = (error, attenuated) => {
  if (error) return colors.error
  if (attenuated) return colors.attenuated
}

export const InputTemplate = styled.input`
  background-color: #FBFCFD;
  border: 1px solid rgba(19, 34, 149, 0.1);
  border-bottom-width: 2px;
  border-radius: 6px;
  font-family: 'Inter';
  padding: 10px;
  position: relative;
  height: 40px;

  &:focus {
    outline: none;
    background-color: ${colors.white};
    border-bottom-color: ${colors.primary};
  }

  &[type="date"]::-webkit-calendar-picker-indicator {
    left: -15px;
    position: absolute;
  }

  &[type="date"] {
    padding-left: 40px;
  }
`

export const SelectTemplate = styled.select`
  background-color: #FBFCFD;
  border: 1px solid rgba(19, 34, 149, 0.1);
  border-bottom-width: 2px;
  border-radius: 6px;
  font-family: 'Inter';
  padding: 10px;
  height: 40px;
  width:100%;
  cursor: pointer;

  &:focus {
    outline: none;
    background-color: ${colors.white};
    border-bottom-color: ${colors.primary};
  }
`;

export const Option= styled.option`
  margin-bottom:0px;
  height: 40px;
`

export const InputTemplateExtended = styled.textarea`
  background-color: #FBFCFD;
  border: 1px solid rgba(19, 34, 149, 0.1);
  border-bottom-width: 2px;
  border-radius: 6px;
  font-family: 'Inter';
  padding: 10px;
  position: relative;
  height: 100%;
  resize: none;
  &:focus {
    outline: none;
    background-color: ${colors.white};
    border-bottom-color: ${colors.primary};
  }
`

export const Container = styled.div`  
  background-color: ${colors.white};
  box-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.07),
    0px 2px 4px rgba(0, 0, 0, 0.05),
    0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 100%;
`

export const TitleWrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid #D3DAE6;
  display: flex;
  margin: 0 0 20px 0;
  padding: 20px 20px 6px 30px;
  width: 100%;
`

export const TitleContainer = styled.div`
  display: flex;
  width: 25%;
  align-items: center;
  justify-content: space-between;
`
export const TitleSection = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 0% 5%;
`

export const Title = styled.h1`
  color: ${colors.lightBlack};
  font-family: 'Inter';
  font-size: 1.100rem;
  font-weight: 700;
  margin-right: 6px;
  width: 30%;
  border-bottom: 1px solid #D3DAE6;
`

export const NewUserTitle = styled.h1`
  color: ${colors.lightBlack};
  font-family: 'Inter';
  font-size: 1.375rem;
  font-weight: 700;
  margin-right: 6px;
`

export const DniTitle = styled.p`
  color: ${colors.primary};
  font-family: 'Inter';
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 10px;
  margin: 0;
`

export const FillSectionWrapper = styled.div`
  display: flex;
  min-height: 300px;
  flex-direction: row;
  justify-content: space-around;
  padding: 2% 5%;
  width: 100%;
`

export const FillSection = styled.section`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const SeparatorTitleSection = styled.div`
  display: flex;
  justify-content: center;
  width: 5%;
`

export const SeparatorFillSection = styled.div`
  display: flex;
  justify-content: center;
  width: 5%;

  &::after {
    background-color: rgba(211, 218, 230, 0.5);
    content: '';
    width: 2px;
  }
`


export const Label = styled.label(
  ({ error, attenuated }) => css`
  color: ${setColor(error, attenuated)};
  font-family: 'Inter';
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
`)

export const InputWrapper = styled.div(({ extended }) => css`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  width: 100%;
  height: ${extended ? '100%' : 'auto'};
  &:focus-within {
    & label {
      color: ${colors.primary};
    }
  }
`)

export const InputWithIconWrapper = styled.div(({ error }) => css`
  align-items: center;
  background-color: #FBFCFD;
  border: 1px solid ${error ? colors.error : `rgba(19, 34, 149, 0.1)`};
  border-bottom-width: 2px;
  border-radius: 6px;
  display: flex;
  font-family: 'Inter';
  padding: 10px;

  &:focus-within {
    background-color: ${colors.white};
    border-bottom-color: ${error ? colors.error : colors.primary};
  }
`)

export const SmallInputWrapper = styled(InputWrapper)(
  ({ percentageWidth = 100 }) => css`
    width: ${percentageWidth}%;
`
)

export const SmallInputWithIconWrapper = styled(InputWithIconWrapper)`
  width: 100%;
  height: 40px;
`
export const RadioGroup = styled.div`
  display: flex;
  flex-direction:row;
  align-items: center;
`

export const RadioInp = styled.input`
  cursor: pointer;
`

export const RadioLab = styled.label`
  display: flex;
  align-items: center; 
  font-size: 14px; 
  font-weight: 600; 
  color: #333; 
  cursor: pointer; 
`


export const MultipleInputContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const InputForIcon = styled.input`
  border: none;
  background-color: #FBFCFD;
  flex-grow: 1;
  outline: none;
  overflow: hidden;
`

export const IconForInput = styled.img`
  height: 14px;
  width: 14px;
`

export const InputFooter = styled.footer`
  color: #69707D;
  font-family: 'Inter';
  font-size: 0.7rem;
  line-height: 18px;
`

export const EyeTypeAndLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  width: 100%;
  height: 100%;
`

export const EyeTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
`

export const RadioEyeWrapper = styled.div(
  ({ haveSeparator }) => css`
    border-bottom: 1px solid ${haveSeparator ? 'rgba(171, 180, 196, 0.3)' : 'transparent'};
    display: flex;
    height: 20px;
    align-items: center;
    width: 100%;
  `
)

export const EyeIconsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const RadioInput = styled.input`
  margin: 4px 4px 0;
`

export const RadioLabel = styled.label`
  margin-left: 3%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const EyeIcon = styled.img`
  height: 15px;
  margin: 0 4px;
  width: 18px;
`

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 30px 20px auto;
  max-width: 260px;
  width: 25%;
`

const buttonColor = (available) => {
  if (available) return colors.primary
  return colors.secondary
}

export const Button = styled.button(
  ({ highlighted, available }) => css`
    align-items: center;
    background-color: ${highlighted ? buttonColor(available) : colors.secondary};
    border: none;
    border-radius: 6px;
    color: ${highlighted ? colors.white : '#005EC4'};
    cursor: pointer;
    display: flex;
    font-family: 'Inter';
    font-size: 0.875rem;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    max-width: 115px;
    width: 100%;
  `
)
