import EditIcon from '../../../../../assets/icons/pencil.png'
import OpenEyeIcon from '../../../../../assets/icons/open_eye.svg'
import CloseEyeIcon from '../../../../../assets/icons/close_eye.svg'
import { usePostStudy } from '../../../../../hooks/NewCase/usePostStudy'
import { useState, useEffect } from 'react'
import { useSearchPatient } from '../../../../../hooks/NewCase/useSearchPatient'
import { SessionExpired } from '../../../../SessionExpired'
import { useSearchStudyService } from '../../../../../hooks/NewCase/Service/useSearchStudyService'
import { setEvaluations } from '../../../../../redux/evaluations'
import { useUpdateUserEvaluations } from '../../../../../hooks/useUpdateUserEvaluations'
import { useDispatch } from 'react-redux'
import { useRequiered } from '../../../../../hooks/NewCase/Requiered/useRequiered'
import { useGetStudyFromPatientId } from '../../../../../hooks/Study/useGetStudyFromPatientId'

import {
  Container,
  TitleWrapper,
  NewUserTitle,
  DniTitle,
  FillSectionWrapper,
  FillSection,
  InputWrapper,
  Label,
  InputTemplate,
  InputFooter,
  SeparatorFillSection,
  InputWithIconWrapper,
  SmallInputWithIconWrapper,
  InputForIcon,
  IconForInput,
  SeparatorTitleSection,
  MultipleInputContainer,
  SmallInputWrapper,
  RadioGroup,
  RadioLab,
  RadioInp,
  EyeTypeAndLabelWrapper,
  EyeTypeWrapper,
  RadioEyeWrapper,
  EyeIconsWrapper,
  RadioLabel,
  RadioInput,
  ButtonsWrapper,
  Button,
  TitleContainer,
  TitleSection,
  Title,
  InputTemplateExtended,
  EyeIcon,
  SelectWrapper,
  SelectTemplate,
  Option
} from './styles'
import { useSelector } from 'react-redux';


export function PhaseTwo({ backPhase, nextPhase }) {
  const { postStudy } = usePostStudy()
  const { patient, patientData, search } = useSearchPatient()
  const { response, searchStudy } = useSearchStudyService()
  const { isValid, dataError, checkRequired, buttonAvailable, setButtonAvailable } = useRequiered("PhaseTwo")
  const [aniosDiabetes, setAniosDiabetes] = useState('');
  const [antecedentes, setAntecedentes] = useState('');
  const {study, getStudyFromPatientId} = useGetStudyFromPatientId()

  const { user } = useSelector(state => state);
  const dispatch = useDispatch()
  const { updateUserEvaluations } = useUpdateUserEvaluations()
  const [data, setData] = useState({
    fecha: new Date(),
    paciente_id: `${patientData && patientData.id}`,
    tipo: '0',
    informe: 'COMPLETAR',
    revisarProfesional: 'false',
    altura: '',
    peso: '',
    aniosConDiabetes: '',
    tieneAntecedentesGlaucoma: '',
    informacionAdicional: ''
  })

  const optAntecedentes = [
    "Si", "No", "No sabe"
  ];

  useEffect(() => {
    setData({ ...data, paciente_id: `${patientData && patientData.id}` })
  }, [patientData])


  useEffect(() => {
    if (user && patientData) {
      getStudyFromPatientId(patientData.id);
    }
  }, [user, patientData]);

  useEffect(() => {
    if(study){
      console.log(study)
      setAntecedentes(study.tieneAntecedentesGlaucoma);
      setAniosDiabetes(study.aniosConDiabetes);
    }
  }, [study])

  useEffect(() => {
    setData(prevData => ({
      ...prevData,
      aniosConDiabetes: aniosDiabetes || '',
      tieneAntecedentesGlaucoma: antecedentes || '',

    }));
  
    if (antecedentes && antecedentes.trim() !== '') {
      setButtonAvailable(true);
    } else {
      setButtonAvailable(false);
    }
  }, [antecedentes, aniosDiabetes]);

  useEffect(() => {
    search();

  }, [])

  useEffect(() => {
    if (response) {
      dispatch(setEvaluations(response));
      updateUserEvaluations();
      nextPhase();
    }
  }, [response]);

  useEffect(() => {
    const valid = async () => {
      if (isValid) {
        const response = await postStudy(data);
        await searchStudy(response.idEstudio);
      } else {
        return
      }
    }
    valid()
  }, [isValid])


  const changeHandler = (event) => {
    const { name, value } = event.target;

    if (name === 'tieneAntecedentesGlaucoma') {
      setAntecedentes(value);
    }

    if(name==='aniosConDiabetes'){
      setAniosDiabetes(value);
    }

    setData(prevData => {
      const newData = { ...prevData, [name]: value };
      checkRequired(newData, false, true);
      return newData;
    });
  };
  
  const saveStudy = async () => {
    checkRequired(data)
  };



  return (
    <Container>
      <SessionExpired />
      <TitleWrapper>
        <TitleContainer>
          <NewUserTitle> Datos de consulta </NewUserTitle>
          <DniTitle> DNI {patient && patient.dni} </DniTitle>
        </TitleContainer>
      </TitleWrapper>

      <TitleSection>
        <Title>Información adicional</Title>
        <SeparatorTitleSection />
        <Title>Datos del estudio</Title>
      </TitleSection>

      <FillSectionWrapper>
        <FillSection>
          <MultipleInputContainer>
            <SmallInputWrapper percentageWidth='45'>
              <Label>
                <Label> Años con diabetes </Label>
                <Label attenuated> (años) </Label>
              </Label>
              <SmallInputWithIconWrapper>
                <InputForIcon defaultValue={aniosDiabetes} placeholder='Opcional' onChange={changeHandler} name='aniosConDiabetes' type='number' />
              </SmallInputWithIconWrapper>
            </SmallInputWrapper>


            <SmallInputWrapper percentageWidth='45'>
              <Label>
                <Label>Antecedentes familiares de glaucoma?</Label>
              </Label>
              <SelectTemplate
                name="tieneAntecedentesGlaucoma"
                value={data.tieneAntecedentesGlaucoma || ''}
                onChange={changeHandler}
              >
                <Option value="" disabled>Seleccionar</Option>
                {optAntecedentes.map((opt) => (
                  <Option key={opt} value={opt}>
                    {opt}
                  </Option>
                ))}
              </SelectTemplate>
            </SmallInputWrapper>
          </MultipleInputContainer>

          <MultipleInputContainer>
            <SmallInputWrapper percentageWidth='45'>
              <Label>
                <Label> Altura </Label>
                <Label attenuated> (cm) </Label>
              </Label>
              <SmallInputWithIconWrapper>
                <InputForIcon placeholder='Opcional' onChange={changeHandler} name='altura' type='number' />
              </SmallInputWithIconWrapper>
            </SmallInputWrapper>

            <SmallInputWrapper percentageWidth='45'>
              <Label>
                <Label> Peso </Label>
                <Label attenuated> (kg) </Label>
              </Label>
              <SmallInputWithIconWrapper>
                <InputForIcon placeholder='Opcional' onChange={changeHandler} name='peso' type='number' />
              </SmallInputWithIconWrapper>
            </SmallInputWrapper>
          </MultipleInputContainer>


          <InputWrapper extended>
            <Label>
              <Label> Información complementaria </Label>
            </Label>
            <InputTemplateExtended placeholder='Opcional' onChange={changeHandler} name='informacionAdicional' />
          </InputWrapper>
        </FillSection>

        <SeparatorFillSection />
        <FillSection>

          <InputWrapper>
            <Label> Fecha de estudio </Label>
            <InputTemplate defaultValue={new Date().toISOString().split('T')[0]} onChange={changeHandler} name='fecha' type='date' />
          </InputWrapper>

          <EyeTypeAndLabelWrapper>
            <Label> Tipo de estudio </Label>
            <EyeTypeWrapper>
              <RadioEyeWrapper>
                <EyeIconsWrapper>
                  <EyeIcon src={OpenEyeIcon} alt='open-eye' />
                  <EyeIcon src={OpenEyeIcon} alt='open-eye' />
                </EyeIconsWrapper>
                <RadioLabel>
                  <RadioInput onChange={changeHandler} defaultChecked type='radio' name='tipo' value='0' />
                  Binocular
                </RadioLabel>
              </RadioEyeWrapper>

              <RadioEyeWrapper>
                <EyeIconsWrapper>
                  <EyeIcon src={OpenEyeIcon} alt='open-eye' />
                  <EyeIcon src={CloseEyeIcon} alt='closed-eye' />
                </EyeIconsWrapper>
                <RadioLabel>
                  <RadioInput onChange={changeHandler} type='radio' name='tipo' value='1' />
                  Monocular derecho
                </RadioLabel>
              </RadioEyeWrapper>

              <RadioEyeWrapper>
                <EyeIconsWrapper>
                  <EyeIcon src={CloseEyeIcon} alt='closed-eye' />
                  <EyeIcon src={OpenEyeIcon} alt='open-eye' />
                </EyeIconsWrapper>
                <RadioLabel>
                  <RadioInput onChange={changeHandler} type='radio' name='tipo' value='2' />
                  Monocular izquierdo
                </RadioLabel>
              </RadioEyeWrapper>
            </EyeTypeWrapper>
          </EyeTypeAndLabelWrapper>
        </FillSection>

      </FillSectionWrapper>

      <ButtonsWrapper>
        <Button onClick={backPhase}> Volver </Button>
        <Button highlighted available={buttonAvailable} onClick={saveStudy}> Guardar </Button>
      </ButtonsWrapper>

    </Container>
  )
}

export default PhaseTwo
