import { PatientInformation, Data, IAQualityControl } from '../..'
import { useStepper } from '../../../hooks/useStepper'
import { useSearchStudy } from '../../../hooks/NewCase/useSearchStudy'
import { useEffect, useState } from 'react'
import { SessionExpired } from '../../SessionExpired'
import { useSelector } from 'react-redux'
import {
  Container,
  CaseInformation
} from './styles'

export function StepThree () {
  const { handleNextStep } = useStepper()
  const { evaluations } = useSelector(state => state)
  const { study, patientData ,getStudy } = useSearchStudy()
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    const fetchStudy = async () => {
      try {
        await getStudy(evaluations.id);
      } catch (error) {
        console.error('Error al obtener el estudio:', error);
      }
    };

    fetchStudy();
  }, []);

  return (
    <Container>
      <SessionExpired />
      <PatientInformation study={study} patientData={patientData} canEdit />

      <CaseInformation>
        {study && <Data study={study} patientData={patientData} selectedData={selectedData}/>}
        {study && <IAQualityControl study={study} nextStep={handleNextStep} updateSelected={setSelectedData}/>}
      </CaseInformation>

    </Container>
  )
}

export default StepThree
