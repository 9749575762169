import styled, { css } from 'styled-components'
import { colors } from '../../../const'

export const ModalTitle = styled.h1`
  color: ${colors.darkBlack};
  font-size: 1.6875rem;
  margin: 0;
  text-align: center;
`

export const ModalDescription = styled.p`
  color:${({ color }) => color || colors.lightBlack};
  line-height: 24px;
  font-size: 1rem;
  text-align: center;
`


export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: 30px;
  width: 100%;
`

export const Button = styled.button(
  ({ highlighted }) => css`
    align-items: center;
    background-color: ${highlighted ? (highlighted === 'cancel' ? colors.error : colors.primary) : colors.secondary};
    border: none;
    border-radius: 6px;
    color: ${highlighted ? colors.white : '#005EC4'};
    cursor: pointer;
    display: flex;
    font-family: 'Inter';
    font-size: 0.875rem;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    margin: 0 2%;
    max-width: 130px;
    width: 100%;
  `
)

export const Input = styled.input`
    background-color: #FBFCFD;
    border: 1px solid rgba(19, 34, 149, 0.1);
    border-bottom-width: 2px;
    border-radius: 6px;
    font-family: 'Inter';
    padding: 10px;
    position: relative;
    height: 40px;
    width:65%;
    margin-bottom:5%;

    &:focus {
      outline: none;
      background-color: ${colors.white};
      border-bottom-color: ${colors.primary};
    }
  `

