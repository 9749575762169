import React from 'react'
import RetinarLogo from '../../assets/images/lightblue_logo_small.svg'
import {
    FooterContainer,
    Logo,
    Version
  } from './styles'

export const Footer = () => {

  return (
    <FooterContainer>
        <Logo src={RetinarLogo} alt='logo' />
        <Version>v2.0.2</Version>
    </FooterContainer>
  )
}

export default Footer