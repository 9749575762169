import styled from 'styled-components'
import { colors } from '../../const'
import { Link as ReactRouterLink } from 'react-router-dom'
import ExternalLinkIcon from '../../assets/icons/external_link.png'

export const PrincipalContainer= styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:space-between;
`

export const OutterContainer = styled.div`
  background-color: ${colors.background};
  display: flex;
  justify-content: center;
  min-height: 93vh;
  min-width: 100vw;
`

export const InnerContainer = styled.div`
  align-items: center;
  justify-content: center;
  background: ${colors.white};
  border-radius: 14px;
  box-shadow: 9px 9px 7px 17px rgba(0, 0, 0, 0.01);
  display: flex;
  flex-direction: column;
  height: 560px;
  margin-top: 170px;
  transition: 0.2s;
  width: 570px;

  @media screen and (max-width: 1550px) {
    margin-top: 6%;
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  padding: 4% 1% 4%;
  position: relative;
  justify-content: center;
  width: 100%;

  &::after {
    border-bottom: 2px solid #C4C4C488;
    bottom: 0;
    content: '';
    margin: 0 auto;
    position: absolute;
    width: 98%;
  }
`

export const Logo = styled.img`
  margin: 0 auto;
  width: 260px;
`

export const Title = styled.h1`
  color: ${colors.lightBlack};
  font-family: 'Inter';
  font-size: 1.6875rem;
  font-weight: 700;
  margin: 4% 0;
`

export const FormWrapper = styled.form`
  flex-grow: 1;
  padding: 0 20%;
  width: 100%;
`

export const InputAndLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3% 0;
  width: 100%;
`

export const InputWrapper = styled.div`
  align-items: center;
  background: #FBFCFD;
  border: 1px solid rgba(19, 34, 149, 0.1);
  border-radius: 4px;
  display: flex;
  position: relative;

  &:focus-within {
    outline: auto;
  }
`

export const Label = styled.label`
  color: ${colors.lightBlack};
  font-family: 'Inter';
  font-size: 0.875rem;
  font-weight: 700;
  margin: 8px 0;
`

export const Input = styled.input`
  border: none;
  flex-grow: 1;
  padding: 10px 12px;
  width: calc(100% - 48px);

  &:focus {
    outline: none;
  }
`

export const LockIcon = styled.img`
  height: 18px;
  padding-left: 8px;
  width: 30px;
`

export const EyeIconWrapper = styled.div`
  align-items: center;
  background: rgba(0, 109, 228, 0.2);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
  display: flex;
  height: 35px;
  justify-content: center;
  width: 48px;
`

export const EyeIcon = styled.img`
`

export const ForgotPassword = styled.a`
  color: ${colors.primary};
  font-size: 0.875rem;
  font-weight: 500;
  text-decoration: none;

  &::after {
    content: url(${ExternalLinkIcon});
    margin: 0 6px;
  }

  &:hover {
    text-decoration: underline;
    cursor:pointer;
  }
`

export const Button = styled.button`
  background-color: ${colors.primary};
  border: none;
  border-radius: 6px;
  color: ${colors.white};
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-top: 10%;
  padding: 10px 0;
  max-width: 112px;
  width: 100%;
`

export const Link = styled(ReactRouterLink)`
  margin: 0 auto;
`

export const ErrorMessage = styled.p`
  color: ${colors.error};
  font-family: 'Inter';
  font-size: 0.875rem;
  font-weight: 500;
  margin: 0;
  text-align: center;
  width: 100%;
`
