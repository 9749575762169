import styled, { css } from 'styled-components'
import { colors, withScrollbarStyled } from '../../const'
import ImageTwo from '../../assets/images/ojoder.png';
import ImageOne from '../../assets/images/ojoizq.png';


export const Container = styled.div(
  () => css`
background-color: ${colors.white};
  box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.06),
    0px 0px 2px rgba(0, 0, 0, 0.04),
    0px 0px 5px rgba(0, 0, 0, 0.04),
    0px 0px 17px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-family: 'Inter';
  justify-content: flex-start;
  padding: 1% 2%;
  width: 45%;

  ${withScrollbarStyled};
  `
)

export const Title = styled.h1`
font-family: 'Inter';
font-size: 1.200rem;
font-weight: 700;
margin: 0;
padding: 0;
color: ${colors.lightBlack};
`
export const SubTitle = styled.h1`
  color: ${colors.lightBlack};
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
`

export const TabsWrapper = styled.div`
  display: flex;
  width: 100%;
`

export const Header = styled.div`
background-color: transparent;
    border-bottom: 2px solid rgba(152, 162, 179, 0.3);
    border-left: none;
    border-right: none;
    border-top: none;
    color: ${colors.primary};
    display: flex;
    padding-bottom: 2%;
    margin-bottom: 5%;
    padding-top: 0%;
    position: relative;
    width: 50%;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    
    &::after {
      bottom: -1px;
      content: '';
      left: 0;
      position: absolute;
      width: 100%;
    } 
  `

export const DataRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;

   @media screen and (max-width: 1280px) {
    width:80%;
    flex-direction:column;
  }
`

export const ReportData = styled.div`
display: flex;
width: 100%;
height: 100%;
justify-content: space-between;
align-items: flex-start;
flex-direction: column;
`

export const Information = styled.div`
display:flex;
width:100%;
height:100%;
justify-content: space-between;
align-items: center;
flex-direction:column;
`

export const ImageEyeSelected = styled.div`
display:flex;
width:150px;
height:150px;
justify-content: center;
align-content:center;
background-image: url(${props => props.selectedData === 1 ? ImageOne : ImageTwo});
background-size: cover;
background-position: center;
margin-bottom:50px;
`

export const Separator = styled.div`
  background-color: rgba(211, 218, 230, 0.5);
  height: 2px;
  margin: 4% 0;
  width: 100%;
`

export const LeftContent = styled.div`
  width: 12%;
`

export const RightContent = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  width: 88%;


  @media screen and (max-width: 1280px) {
    gap:20px;  
  }

`

export const Icon = styled.img`
`

export const LabelAndValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Label = styled.label`
  color: ${colors.darkBlack};
  font-size: 0.72rem;
  font-weight: 700;
  letter-spacing: 0.1px;
`

export const PapersText = styled.p`
  color: ${colors.lightBlack};
  font-size: 0.875rem;
  margin: 0;
`

export const LabelAndContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ContactWrapper = styled.div`
  display: flex;
  margin: 1.5% 0;
`

export const IconAndLabelWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  width: 30%;
`

export const Contact = styled.a`
  color: ${colors.primary};
  flex-grow: 1;
  font-size: 0.875rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
const getButtonColor = (type) => {
  if (type === 'primary') return colors.white
  if (type === 'secondary') return '#005EC4'
  return '#005EC4'
}

const getButtonBackground = (type) => {
  if (type === 'primary') return colors.primary
  if (type === 'secondary') return 'rgba(0, 109, 228, 0.2)'
  return colors.white
}





export const ParametersWrapper = styled.div`
  display: grid;
  font-family: 'Inter';
  gap: 14px 4px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin-top: 16px;
  width: 100%;
`

export const ParametersCell = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: column;
  width: 100%;
`

export const ParameterTitle = styled.p`
  color: #1A1C21;
  font-size: 0.75rem;
  margin: 0;
`

export const ParameterDescription = styled.p`
  color: #0071C2;
  font-size: 0.875rem;
  margin: 0;
`

export const LabelAndDescriptionWrapper = styled.div`
  font-family: 'Inter';
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 20px;
`



export const Description = styled.div`
  display: grid;
  font-size: 0.75rem;
  gap: 12px;
  grid-template-columns: 1fr;
  margin-top: 6px;
  width: 100%;
`

export const SignatureWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const Signature = styled.img``

export const DigitalSignatureWrapper = styled.div`
  color: #808488;
  display: flex;
  gap: 8px;
  flex-direction: column;
  font-family: 'Inter';
  width: 100%;
`

export const DigitalSignatureTitle = styled.p`
  font-size: 0.5rem;
  margin: 0;
  text-transform: uppercase;
`

export const DigitalSignatureValue = styled.p`
  font-size: 0.625rem;
  margin: 0;
  word-break: break-all;
`
export const ButtonsWrapper = styled.footer`
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: auto;
  width: 100%;
`

export const Button = styled.button(
  ({ type }) => css`
    align-items: center;
    background-color: ${getButtonBackground(type)};
    border: none;
    border-radius: 6px;
    color: ${getButtonColor(type)};
    cursor: pointer;
    display: flex;
    font-family: 'Inter';
    font-size: 0.875rem;
    font-weight: 500;
    gap: 6px;
    height: 40px;
    justify-content: center;
    margin-top: 20px;
    min-width: 112px;
    padding: 0 12px;
  `
)

export const ButtonIcon = styled.img``


export const ContactIcon = styled.img`
  height: 15px;
  margin-right: 10%;
  width: 15px;
`



export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 1%;
`


export const ThirdRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SelectContainer = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  margin-top: 12px;
  width: 100%;
`

export const SelectWrapper = styled.div`
  align-self: end;
  display: flex;
  flex-direction: column;
`

export const SelectLabel = styled.label`
  font-size: 0.75rem;
  margin-bottom: 6px;
`

export const SelectTemplate = styled.select`
  background-color: #FBFCFD;
  border: 1px solid rgba(19, 34, 149, 0.1);
  border-bottom-width: 2px;
  border-radius: 6px;
  font-family: 'Inter';
  padding: 10px;
  position: relative;
  width: 100%;

  &:focus {
    outline: none;
    background-color: ${colors.white};
    border-bottom-color: ${colors.primary};
  }
`

export const Textarea = styled.textarea`
  border: 1px solid #D3DAE6;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #FBFCFD;
  flex-grow: 1;
  font-family: 'Inter';
  font-size: 0.875rem;
  margin-top: 14px;
  max-width: 100%;
  overflow: hidden;
  padding: 6px;
  width: 100%;

  &:focus {
    outline: none;
  }
`

export const TextareaFooter = styled.div`
  background-color: #F5F7FA;
  border-bottom: 1px solid #D3DAE6;
  border-left: 1px solid #D3DAE6;
  border-right: 1px solid #D3DAE6;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  height: 40px;
  padding: 10px;
  width: 100%;
`

export const EditIconsWrapper = styled.div`
  align-items: center;
  border-right: 1px solid #D3DAE6;
  display: flex;
  justify-content: center;
  padding-right: 12px;
`

export const EditIcon = styled.img`
  cursor: pointer;
  margin-left: 6px;
`