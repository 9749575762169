import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Asegúrate de importar Link si lo usas
import RetinarLogo from '../../assets/images/lightblue_logo.png';
import Lock from '../../assets/icons/lock.svg';
import OpenEye from '../../assets/icons/open_eye.svg';
import CloseEye from '../../assets/icons/close_eye.svg';
import { useLogin } from '../../hooks/Login/useValidate'; // Verifica si necesitas este hook
import Footer from '../../components/Footer';
import ForgotPasswordModal from '../../components/Modal/CustomModals/ForgotPasswordModal'; // Asegúrate de que la ruta sea correcta
import {
  OutterContainer,
  InnerContainer,
  LogoWrapper,
  Logo,
  Title,
  FormWrapper,
  InputAndLabelWrapper,
  InputWrapper,
  Input,
  Label,
  LockIcon,
  EyeIconWrapper,
  EyeIcon,
  ForgotPassword,
  Button,
  ErrorMessage,
  PrincipalContainer
} from './styles';
import { useValidate } from '../../hooks/Login/useValidate'; 
import { useModal } from '../../hooks/useModal';


export function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    userName: '',
    password: ''
  });

  const [modalRef, openModal, closeModal] = useModal();


  const { validate, error } = useValidate(); 

  const toggleShowPassword = () => {
    setShowPassword(prevState => !prevState);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await validate(data);
  };

  const changeHandler = event => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleForgotPassword = () => {
    openModal(); // Abre el modal cuando el usuario hace clic en "Olvidé mi contraseña"
  };

  return (
    <PrincipalContainer>
      <OutterContainer>
        <InnerContainer>
          <LogoWrapper>
            <Link to='/'>
              <Logo src={RetinarLogo} alt='logo' />
            </Link>
          </LogoWrapper>

          <Title> Iniciar sesión </Title>
          <ErrorMessage> {error && 'Usuario o contraseña incorrectos'} </ErrorMessage>
          <FormWrapper onSubmit={handleSubmit}>
            <InputAndLabelWrapper>
              <Label> Usuario </Label>
              <InputWrapper>
                <Input placeholder='Usuario' name='userName' onChange={changeHandler} />
              </InputWrapper>
            </InputAndLabelWrapper>

            <InputAndLabelWrapper>
              <Label> Contraseña </Label>
              <InputWrapper>
                <LockIcon src={Lock} alt='lock' />
                <Input type={showPassword ? 'text' : 'password'} placeholder='********' autoComplete='off' name='password' onChange={changeHandler} />
                <EyeIconWrapper onClick={toggleShowPassword}>
                  <EyeIcon src={showPassword ? OpenEye : CloseEye} alt='toggle-password' />
                </EyeIconWrapper>
              </InputWrapper>
            </InputAndLabelWrapper>

            <ForgotPassword  type="button" onClick={handleForgotPassword}>
              Olvidé mi contraseña
            </ForgotPassword>

            <Button>
              Entrar
            </Button>
          </FormWrapper>
        </InnerContainer>
      </OutterContainer>
      <Footer />

      <ForgotPasswordModal ref={modalRef} close={closeModal} />
    </PrincipalContainer>
  );
}

export default Login;
