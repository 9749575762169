import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Statistics from '../../assets/icons/statistics.svg'
import StatusArrow from '../../assets/icons/up-blue-arrow.svg'
import { EXAM_TYPE_TEXT } from './helpers'
import PatientInformation from '../../components/PatientInformation'
import InformationCell from './InformationCell'
import { DxResume } from './InformationCell/CellContent'
import { useDateFormatter } from '../../hooks/useDateFormatter'
import { SessionExpired } from '../../components/SessionExpired'
import { useCases } from '../../hooks/Cases/useCases'
import { useSetCase } from '../../hooks/Cases/useSetCase'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import {
  Container,
  Header,
  TitleWrapper,
  TitleIcon,
  Title,
  MonthlyCasesWrapper,
  MonthlyTitle,
  MonthlyValues,
  MonthlyTotal,
  MonthlyPercentageWrapper,
  MonthlyPercentageIcon,
  MonthlyPercentage,
  Subheader,
  SearchWrapper,
  Input,
  FilterWrapper,
  SelectWrapper,
  SelectLabel,
  Select,
  Option,
  MainContent,
  Table,
  TableHeader,
  TableHeaderData,
  TableBodyRow,
} from './styles'
import PaginationFooter from '../../components/PaginationFooter'
import { toggleToPatientUser } from '../../redux/user'

export function Evaluations () {

  const STATUS ={
    'incompleto': 'Incompleto',
    'no referido': 'No referido',
    'diagnosticado': 'Diagnosticado',
    'pendiente': 'Informe pendiente',
    'informado': 'Informado',
    'informando': 'Informando',
  }
  const { user, evaluations } = useSelector(state => state)
  const history = useHistory()
  
  const [filters, setFilters] = useState({
    status: 'all',
    search: '',
    period: 0
  })

  const { formatDate } = useDateFormatter()
  const {setCase} = useSetCase()

  const { calculateListOfPages, setCasesPerPage, setCurrentPage, setCases, search, statistics, currentPage, casesToShow} = useCases()

  
  const handleChangePage = (page) => {
    setCurrentPage(page)
  }
  
  const handleChangeFilter = (event) => {
    const updatedFilters = { ...filters, [event.target.name]: event.target.value };
    setFilters(updatedFilters);
    search(updatedFilters);
  }

  const handleChangeRowsPerPage = (event) => {
    setCasesPerPage(event.target.value)
  }

  const viewCase = async (singleCase) => {
    // Funcion unicamente para el paciente por el momento
    if(user.role === 'patient' ){//|| user.role === 'technical'
    await setCase(singleCase)
    history.push('/report')
    }
  }
  


  useEffect(() => {
    console.log('evaluations', user.evaluations)
    setCases(user.evaluations || [])
  }, []);

  return (
    <Container>

      <SessionExpired />
      {user.role === 'patient' && (
          <PatientInformation study={evaluations} patientData={user.data} />
      )}

      <Header>
        <TitleWrapper>
          <TitleIcon src={Statistics} alt='statistics-icon'/>
          <Title> Listado de evaluaciones </Title>
        </TitleWrapper>
        
        {user.role !== 'patient' && (
          <MonthlyCasesWrapper>
            <MonthlyTitle> Realizados este mes </MonthlyTitle>
            <MonthlyValues>
              <MonthlyTotal> {statistics.total} </MonthlyTotal>
              <MonthlyPercentageWrapper>
                <MonthlyPercentageIcon src={StatusArrow} alt='percentage-status'/>
                <MonthlyPercentage> {statistics.percentage}% </MonthlyPercentage>
              </MonthlyPercentageWrapper>
            </MonthlyValues>
          </MonthlyCasesWrapper>
        )}
        
      </Header>

      <Subheader>
        <SearchWrapper  onChange={handleChangeFilter}>
          <Input name='search' placeholder={user.role === 'patient' ? 'Buscar...' : 'Buscar por datos de paciente...'} />
        </SearchWrapper>

        <FilterWrapper>
          <SelectWrapper>
            <SelectLabel> Periodo </SelectLabel>
            <Select name='period' onChange={handleChangeFilter}>
              <Option value={0}> Todos </Option>
              <Option value={1}> Último mes </Option>
              <Option value={3}> Últimos 3 meses </Option>
              <Option value={12}> Último año </Option>
            </Select>
          </SelectWrapper>

          <SelectWrapper>
            <SelectLabel> Estado </SelectLabel>
            <Select name='status' onChange={handleChangeFilter}>
              <Option value={'all'}> Todos </Option>
              {Object.keys(STATUS).map((status, index) => (
                <Option value={status} key={index}> {STATUS[status]} </Option>
              ))}
            </Select>
          </SelectWrapper>
        </FilterWrapper>
      </Subheader>

      <MainContent>
        <Table>
          <TableHeader role={user.role}>
            <TableHeaderData>Fecha</TableHeaderData>
            {user.role!=='patient' && <TableHeaderData>Paciente</TableHeaderData>}
            {user.role!=='patient' && <TableHeaderData>DNI</TableHeaderData>}
            <TableHeaderData>Tipo de examen</TableHeaderData>
            <TableHeaderData statusHeader>Estado</TableHeaderData>
            <TableHeaderData statusHeader>Resumen diagnóstico</TableHeaderData>
          </TableHeader>

          {casesToShow.map(singleCase => (
            <TableBodyRow role={user.role} key={singleCase.id}>
              <InformationCell> {formatDate(singleCase.fecha)} </InformationCell>
              {user.role!=='patient' && <InformationCell> {singleCase.paciente.nombre} {singleCase.paciente.apellido} </InformationCell>}
              {user.role!=='patient' && <InformationCell> {singleCase.paciente.dni} </InformationCell>}
              <InformationCell type='exam' examType={singleCase.tipo}>
                {EXAM_TYPE_TEXT[singleCase.tipo]}
              </InformationCell>
              <InformationCell 
                type='status' 
                onClick={() => viewCase(singleCase)} 
                reportActive={STATUS[singleCase.estado]}
              >
                {STATUS[singleCase.estado]}
              </InformationCell>
              <InformationCell type='dx' status={singleCase.estado} iaDx={singleCase.resumen_DX_IA} drDx={singleCase.resumen_DX_MANUAL}>
                <DxResume ia={singleCase.resumen_DX_IA} dr={singleCase.resumen_DX_MANUAL}/>
              </InformationCell>
            </TableBodyRow>
          ))}
        </Table>

        <PaginationFooter
          currentPage={currentPage}
          listOfPages={calculateListOfPages()}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </MainContent>
    </Container>
  )
}

export default Evaluations
