// ForgotPasswordModal.jsx
import Modal from "../..";
import { forwardRef, useState } from "react";
import { ModalDescription, ModalTitle, ButtonsWrapper, Button, Input } from "../styles";
import { usePassword } from '../../../../hooks/Password/usePassword';
import { useModal } from '../../../../hooks/useModal';

const ForgotPasswordModal = forwardRef(({ close }, ref) => {
    const [username, setUsername] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const { uploadPassword } = usePassword();
    const { handleClose } = useModal();

    const handleForgotPassword = async () => {
        const response = await uploadPassword(username);

        if (response.success) {
            setStatusMessage("Revisa tu email donde encontrarás tu nueva contraseña.");
        } else {
            setStatusMessage("Usuario no encontrado");
        }
    };

    const reset = () => {
        setUsername('');
        setStatusMessage('');

    };

    const cancel = () => {
        reset();
        handleClose;
        close();
    };

    return (
        <Modal ref={ref}>
            <ModalTitle>Recuperar Contraseña</ModalTitle>

            {statusMessage ? (
                <ModalDescription color={statusMessage === "Usuario no encontrado" ? "red" : undefined} >{statusMessage}</ModalDescription>
            ) : (
                <>
                    <ModalDescription>
                        Ingresa tu nombre de usuario y te enviaremos tu nueva contraseña al correo electrónico registrado.
                    </ModalDescription>
                    <Input
                        placeholder="Nombre de usuario"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <ButtonsWrapper>
                        <Button onClick={cancel}>Cancelar</Button>
                        <Button highlighted onClick={handleForgotPassword}>Enviar</Button>
                    </ButtonsWrapper>
                </>
            )}

            {statusMessage && (
                <ButtonsWrapper>
                    {statusMessage === "Usuario no encontrado" ? (
                        <Button onClick={reset}>Reintentar</Button>
                    ) : (
                        <Button onClick={cancel}>Cerrar</Button>
                    )}
                </ButtonsWrapper>
            )}
        </Modal>
    );
});

export default ForgotPasswordModal;
