import { useSelector } from "react-redux"
import { useState } from "react"
import { URLS } from "../../const/nav"
export const useSearchInstitution = () => {

    const { user } = useSelector(state => state)
    const [institution, setInstitution] = useState(null)

    const getInstitution = async (id) => {
        const response = await fetch(`${URLS.BACKEND_URL}/instituciones/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${user.token}`
            }
        })
        const data = await response.json()
        setInstitution(data)
    }

    return {institution, getInstitution}

}
