import { useSelector } from "react-redux"
import { URLS } from "../../const/nav"

export const usePassword = () => {
    const { user } = useSelector(state => state)


    const uploadPassword = async (username) => {
        const r = await fetch(`${URLS.BACKEND_URL}/usuarios/olvide-mi-contrasenia`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${user.token}`
            },
            body: JSON.stringify({ username: username })
        });

        const isError = r.status !== 200;

        return { success: !isError, data: isError ? null : await r.json() };
    }

    return {uploadPassword}
}