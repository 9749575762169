import styled, { css } from 'styled-components'
import { colors } from '../../../../../const'

export const ModalTitle = styled.h1`
  color: ${colors.darkBlack};
  font-size: 1.6875rem;
  margin: 0;
  text-align: center;
`

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-top: 20px;
  width: 100%;
`

export const Image = styled.img.attrs(props => ({
  style: {
    height: '100%',
    maxWidth: '100%',
    width: '100%',
    objectFit: 'contain',
    transform: `scale(${props.zoom}) translate(${props.x}px, ${props.y}px)`,
    cursor: props.zoom > 1 ? 'grab' : 'auto',
    userSelect: 'none'
  },
}))``;


export const ImageContainer = styled.div`
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-top: 24px;
`

export const Span = styled.span`
  color:${colors.background};
  margin-bottom:2px;
  font-family: 'Inter';
  font-size: 0.875rem;
  font-weight: 500;
`

export const Button = styled.button(
  ({ highlighted }) => css`
    align-items: center;
    background-color: ${highlighted ? '#0071C2' : colors.secondary};
    border: none;
    border-radius: 6px;
    color: ${highlighted ? colors.white : '#005EC4'};
    cursor: pointer;
    display: flex;
    font-family: 'Inter';
    font-size: 0.875rem;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    margin: 0 2%;
    max-width: 130px;
    width: 100%;
  `
)

export const FloatingMenu = styled.div(
  () => css`
    align-items: center;
    background-color: transparent;
    display: flex;
    gap: 10px;
    left: 0;
    width: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
  `
)

export const RadioWrapper = styled.div`
  background-color: #FFF;
  border-radius: 3px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 4px;
  position: absolute;
  top: 40px;
`


export const RadioInput = styled.input`
  
`

export const RadioLabel = styled.label`
  padding: 4px;
  white-space: nowrap;
`

export const IAToggler = styled.div(
  ({ active, withGap }) => css`
    align-items: center;
    background-color: ${ active ? '#0D90E0' : '#ABB4C4'};
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: pointer;
    display: flex;
    height: 38px;
    gap: ${withGap ? '6px' : '0'};
    justify-content: space-evenly;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
    left: 20px;
    padding: 6px 8px;
    top: 10px;

    &:hover {
      background-color: #0D90E0;
    }
  `
)

export const TogglerText = styled.p`
  color: white;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 1rem;
  margin: 0 4px;
`

export const TogglerIcon = styled.img`
  margin: 0 4px;
`
