import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setResetCases } from '../../../redux/cases'
import { stepReset } from '../../../redux/steps'
import CheckIcon from '../../../assets/icons/check_bg_green.svg'
import {
  Container,
  SuccessImage,
  SuccessWrapper,
  SuccesImageWrapper,
  SuccessTitle,
  SubtitleSection,
  SuccessSubTitle,
  ButtonsWrapper,
  Button,
} from './styles'

export function CaseSendedNotReferible () {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleResetCase = () => {
    dispatch(setResetCases())
    dispatch(stepReset())
  }

  const handleGoToHome = () => {
    history.replace('/home')
    handleResetCase()
  }

  return (
    <Container>
      <SuccessWrapper>
        <SuccesImageWrapper>
          <SuccessImage src={CheckIcon} alt='success' />
        </SuccesImageWrapper>

        <SuccessTitle> Su control está finalizado  </SuccessTitle>

        <SubtitleSection>
          <SuccessSubTitle> No se observaron signos de referibilidad y usted ha decidido no referir su estudio a un oftalmólogo. <br/>
                            El estudio fue enviado por correo electrónico para que lo conserve.<br/>
                            Le enviaremos un recordatorio dentro de 10 meses para que vuelva a repetir el control.<br/></SuccessSubTitle>
        </SubtitleSection>

        <ButtonsWrapper>
          <Button onClick={handleGoToHome}> Volver al inicio </Button>

          <Button highlighted onClick={handleResetCase}> Realizar nuevo estudio </Button>

        </ButtonsWrapper>
      </SuccessWrapper>
    </Container>
  )
}

export default CaseSendedNotReferible
